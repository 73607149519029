import React from 'react'

const TextArea = ({ data, label, parent, keyname, name, type, onChange }) => {
	if (parent !== undefined && parent[keyname] !== undefined ) {
		data = parent[keyname];
	}
	return (
		<div className="form-group">
    		<label>{ label }</label>
    		<textarea className="form-control" name={ name } defaultValue={ data } onChange={onChange} />
  		</div>
	);
}


TextArea.propTypes = {
}

export default TextArea