import { connect } from 'react-redux';
import { filterByDomain, fetchLeads, toggleLeadDetails, googleLogin } from '../actions';
import Login from '../components/Login.js';


const mapStateToProps = (state, ownProps) => {
  return {
    accessToken: state.user.accessToken,
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    onSuccessfulLogin: googleUser => {
      dispatch(googleLogin(googleUser));
    },
  }
}

const ConnectedLogin = connect(
  mapStateToProps,
  mapDispatchToProps
)(Login);

export default ConnectedLogin