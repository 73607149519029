import React from 'react'
import PropTypes from 'prop-types'
import Lead from './Lead'
import { Link } from 'react-router-dom'

const LeadList = ({ leads, filter, expanded, onLeadClick, onFilterClose, onToggleMore, onToggleTest, onToggleDelete }) => {

	if (leads.isFetching) {
		return (
			<div className="col-sm-12">
				<p>Loading...</p>
			</div>
		);
	}

	const excludes = ['_id', 'form_id', 'email2', 'submitted_on', 'domain', 'validated', 'redirect', 'inserted_epoch'];
	let cols = [], rows = [];
	let filteredBy = "";
	console.log("LEADS: ", leads.data);
	// Check if not empty array
	if (leads.data && leads.data !== undefined) {
		for (let lead of leads.data) {
			let row = [];
			for (let key of Object.keys(lead)) {
				if (!cols.includes(key) && !excludes.includes(key)) {
					cols.push(key);
				}
				if (cols.indexOf(key) !== false) {
					row[cols.indexOf(key)] = '"' + lead[key] + '"';
				}
			}
			rows.push(row);
		}
		let csvContent = "data:text/csv;charset=utf-8,";
		csvContent += cols.join(",") + "\r\n";
		rows.forEach(function (rowArray) {
			let row = rowArray.join(",");
			csvContent += row + "\r\n";
			// fix broken CSV download Link
			csvContent = csvContent.replace(/#/g, "%20");
		});
		var encodedUri = encodeURI(csvContent);

		if (filter) {
			filteredBy = (
				<div className="row">
					<div className="col-12 col-sm-12 col-md-8 p-2">
						<span>Filtering by </span>
						<span className="badge badge-pill badge-secondary">{filter} <Link to="/"><i className="fas fa-times p-2 text-light"></i></Link>
						</span>
					</div>
					<div className="col-12 col-sm-12 col-md-4 text-right">
						<a className="btn btn-default" href={encodedUri}>Download as CSV</a>
					</div>
				</div>
			);
		}
	}


	return (
		<div className="col-sm-12">
			{filteredBy}
			<div className="list-group">
				{leads.data.map((lead, index) => (
					<Lead key={lead._id} expanded={expanded} lead={lead} onClick={() => onLeadClick(index)} onToggleMore={onToggleMore} onToggleTest={onToggleTest} onToggleDelete={onToggleDelete} />
				))}
			</div>
		</div>
	);
}

export default LeadList
