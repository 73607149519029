import { RECEIVE_LOGIN_TOKEN, AUTH_FAILED } from "../actions/index";
import * as moment from 'moment';

document.cookie = 'inapp=true';
let cookies = document.cookie.split(';').reduce((acc, cur) => {
	let split = cur.split('=');
	acc[split[0].trim()] = split[1].trim();
	return acc;
}, {});

const initialState = {
  accessToken: cookies['accessToken'] || null,
};

const usersReducer = (state = initialState, action) => {
	let invalidators = state.invalidators;
  	switch (action.type) {
    	case RECEIVE_LOGIN_TOKEN:
      		return Object.assign({}, state, {
        		accessToken: action.accessToken
      		});
    	case AUTH_FAILED:
      		return Object.assign({}, state, {
        		accessToken: null
      		});
    	default:
      		return state;
  	}
};

export default usersReducer;