import React from 'react'
import PropTypes from 'prop-types'
import Lead from './Lead'
import LeadName from './LeadName'
import { Link } from 'react-router-dom'

const DetailList = ({ leads, expanded, filter, onCloseClick }) => {
	let reducer = (acc, cur) => {
		if (expanded === cur._id) {
			acc = cur;
		}
		return acc;
	};
	let lead = leads.data.reduce(reducer, null);
	if (lead) {
		return (
			<div className="col-sm-12">
				<div>
					<p><Link to={"/domains/" + lead.domain}>Back</Link></p>
				</div>
				<div className="card">
					<div className="card-body">
						<div className="container">
							<LeadName lead={lead} />
							{Object.keys(lead).map((key, index) => (
								<div className="row" key={index}>
									<span>{key}:</span>
									<span>&nbsp;{lead[key]}</span>
								</div>
							))}
						</div>
					</div>
				</div>
			</div>
		);
	}
	else {
		return (
			<div>No details found.</div>
		);
	}
}


DetailList.propTypes = {
}

export default DetailList
