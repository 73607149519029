import React from "react";
import List from "./List";
import Filter from "./Filter";
import '../App.css';

const Header = () => (
	<header className="App-header">
		<img src="http://www.elliance.com/assets/images/elliance-main-logo.png" className="App-logo" alt="logo" />
		<h1 className="App-title">Landing Page / Leads</h1>
	</header>
);

export default Header;