import React from "react";
import GoogleLogin from "react-google-login";

const Login = ({ accessToken, onSuccessfulLogin }) => {
  if (accessToken) {
    return (<div></div>);
  }
  return (
    <div>
      {/* <GoogleLogin
        clientId="490978297793-oekvugs12d2452ihqfi8vsl7ulqpoktr.apps.googleusercontent.com"
        buttonText="Login with Google"
        onSuccess={onSuccessfulLogin}
        cookiePolicy={"single_host_origin"}
      /> */}
    </div>
  );
};

export default Login;
