import { connect } from 'react-redux'
import { filterByDomain, toggleLeadDetails } from '../actions'
import { fetchLeads, fetchLeadsIfNeeded, doUpdateLead } from '../actions/leads'
import LeadList from '../components/LeadList'

const mapStateToProps = state => {
  return {
    leads: state.leads,
    filter: state.app.filter,
    expanded: state.expanded
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
	// if detects that it's passed a filter, let's update.
	if (ownProps.filter) {
		dispatch(filterByDomain(ownProps.filter));
    	dispatch(fetchLeadsIfNeeded());
	}
  return {
    onLeadClick: id => {
      //dispatch(expandLead(id));
    },
    onFilterClose: () => {
      dispatch(filterByDomain(''));
      dispatch(fetchLeads(''));
    },
    onToggleMore: (id, expanded, expand) => {
      	dispatch(toggleLeadDetails(id, expanded, expand));
    },
    onToggleTest: (lead) => {
    	lead._test = !lead._test;
    	dispatch(doUpdateLead(lead));
    },
    onToggleDelete: (lead) => {
    	lead._deleted = !lead._deleted;
    	dispatch(doUpdateLead(lead));
    }
  }
}

const VisibleLeadList = connect(
  mapStateToProps,
  mapDispatchToProps
)(LeadList);

export default VisibleLeadList