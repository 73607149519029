import { REQUEST_FORMS, RECEIVE_FORMS, CREATE_FORM } from "../actions/forms";

const initialState = {
  data: [],
  isFetching: false,
  didInvalidate: true,
  lastUpdated: null
};

const formsReducer = (state = initialState, action) => {
	let forms = {};
  	switch (action.type) {
   		case RECEIVE_FORMS:
      		forms = {
      			isFetching: false,
      			didInvalidate: false,
      			data: action.forms,
      			lastUpdated: action.receivedAt
      		};
      		return Object.assign({}, state, forms);
      	case REQUEST_FORMS:
      		forms = {
      			isFetching: true,
      			didInvalidate: false
      		};
      		return Object.assign({}, state, forms);
      	case CREATE_FORM:
      		forms = {
      			didInvalidate: true
      		};
      		return Object.assign({}, state, forms);
    	default:
      		return state;
  	}
};

export default formsReducer;