import React from 'react';
import ReactDOM, { render } from 'react-dom';
import { Provider } from "react-redux";
import PropTypes from 'prop-types'
import './index.css';
import App from './components/App';
import registerServiceWorker from './registerServiceWorker';
import store from './store/index';
import { BrowserRouter as Router, Route } from 'react-router-dom'

const Root = ({ store }) => (
  <Provider store={store}>
  	<App />
  </Provider>
)

Root.propTypes = {
  store: PropTypes.object.isRequired
}

render(
  <Root store={store} />,
  document.getElementById("root")
);
