import { REQUEST_DOMAINS, RECEIVE_DOMAINS } from "../actions/domains";
import { FILTER_BY_DOMAIN, TOGGLE_TESTS_FILTER, TOGGLE_DELETED_FILTER, UPDATE_DATE_FILTER } from "../actions/index";

const initialState = {
  data: [],
  isFetching: false,
  didInvalidate: true,
  lastUpdated: null
};

const domainsReducer = (state = initialState, action) => {
	let domains = {};
  	switch (action.type) {
   		case RECEIVE_DOMAINS:
      		domains = {
      			isFetching: false,
      			didInvalidate: false,
      			data: action.domains,
      			lastUpdated: action.receivedAt
      		};
      		return Object.assign({}, state, domains);
    	case REQUEST_DOMAINS:
      		domains = {
      			isFetching: true,
      			didInvalidate: false
      		};
      		return Object.assign({}, state, domains);
    	case TOGGLE_TESTS_FILTER:
    		return Object.assign({}, state, {
        		didInvalidate: true,
      		});
    	case TOGGLE_DELETED_FILTER:
    		return Object.assign({}, state, {
        		didInvalidate: true,
      		});
    	case UPDATE_DATE_FILTER:
    		return Object.assign({}, state, {
        		didInvalidate: true,
      		});
    	default:
      		return state;
  	}
};

export default domainsReducer;