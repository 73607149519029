import React from "react";
import { connect } from "react-redux";
import FilterLink from '../containers/FilterLink'
import '../App.css';

const mapStateToProps = state => {
  return { leads: state.leads };
};

const LeadList = ({ leads }) => {
	if (leads === undefined) {
		leads = [];
	}
	let counter = 0;
	return (
  <div className="list-group list-group-flush">
    {
    	leads.map(el => { 
    		counter++;
    		return (
      <div className="list-group-item lead-item" key={counter}>
      	<div className="row">
      		<span className="lead-name">
        		{el.first_name} {el.last_name}
        	</span>
        	<span className="lead-email"> ({el.email})</span>
        </div>
        <div className="row">
        	<div className="lead-url">received from: 
        		<FilterLink filter={el.domain}>
      				{el.domain}
    			</FilterLink>
    		</div>
        </div>
        <div className="row">
        	<div className="lead-url">received at: {el.inserted_datetime}</div>
        </div>
        <div className="lead-misc row">
        </div>
      </div>
    		);
    	}
    	)
    }
  </div>
)
};

const List = connect(mapStateToProps)(LeadList);

export default List;