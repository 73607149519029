import { connect } from 'react-redux'
import { filterByDomain, fetchLeads, toggleLeadDetails } from '../actions'
import DetailList from '../components/DetailList'


const mapStateToProps = (state, ownProps) => {
  return {
    leads: state.leads,
    filter: state.filter,
    expanded: ownProps.id || state.expanded
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    onCloseClick: id => {
      //dispatch(expandLead(id));
      console.log("toggling", id);
    },
  }
}

const VisibleDetails = connect(
  mapStateToProps,
  mapDispatchToProps
)(DetailList);

export default VisibleDetails