import thunkMiddleware from 'redux-thunk'
import { createStore, applyMiddleware } from "redux";
import rootReducer from "../reducers/index";
import { fetchDomainsIfNeeded } from '../actions/domains';
import { fetchFormsIfNeeded } from '../actions/forms';

const store = createStore(
	rootReducer,
	applyMiddleware(
    	thunkMiddleware,
  	)
);

store.dispatch(fetchDomainsIfNeeded());
store.dispatch(fetchFormsIfNeeded());

export default store;