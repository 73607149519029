import { connect } from 'react-redux'
import FormList from '../components/FormList';
import { fetchFormsIfNeeded, createForm } from '../actions/forms';


const mapStateToProps = state => {
  return {
    forms: state.forms,
    accessToken: state.accessToken,
    filter: state.filter
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    onCreateClick: form => {
      console.log("In VisibleForms forms.isFetching...");
      dispatch(createForm());
      dispatch(fetchFormsIfNeeded());
    }
  }
}

const VisibleForms = connect(
  mapStateToProps,
  mapDispatchToProps
)(FormList);

export default VisibleForms