import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

const LeadName = ({ lead, withLink }) => {
	if (withLink) {
		return (
		<h5 className="lead-name">
			<Link to={"/lead/"+lead._id}>
    			{lead.first_name} {lead.last_name} <i className="fas fa-angle-right"></i>
        	</Link>
		</h5>
		)
	}
	return (
		<h5 className="lead-name">
    		{lead.first_name} {lead.last_name}
		</h5>
	);
};

LeadName.propTypes = {
}

export default LeadName