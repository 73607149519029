import React from 'react'

const TextBox = ({ data, label, parent, keyname, name, type, onChange }) => {
	if (parent !== undefined && parent[keyname] !== undefined ) {
		data = parent[keyname];
	}
	return (
		<div className="form-group">
    		<label>{ label }</label>
    		<input type={ type } className="form-control" name={ name } defaultValue={ data } onChange={onChange} />
  		</div>
	);
}


TextBox.propTypes = {
}

export default TextBox