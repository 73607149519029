import React from "react";
import { useEffect, useState } from "react";
import jwt_decode from "jwt-decode";
import { getCookie } from './utils.js';
import { receiveLoginToken } from './utils.js';
import Header from "./Header";
import SelectedFilters from "../containers/SelectedFilters";
import Filter from "./Filter";
import VisibleLeads from "../containers/VisibleLeads";
import '../App.css';
import ConnectedRouter from "../containers/ConnectedRouter";
import { BrowserRouter as Router, Route, Link, Switch } from 'react-router-dom';

require('dotenv').config();

const google = window.google;

const App = () => {

	let userObject = ([]);
	const [user, setUser] = useState({});
	let token = getCookie("accessToken");

	function handleCallbackResponse(response) {
		// JSON Web Token from google
		// console.log("Encoded JWT ID TOKEN: " + response.credential);
		userObject = jwt_decode(response.credential);
		setUser(userObject);
		// console.log("USER: ", userObject);
		if (userObject.length != 0) {
			// store encoded token
			receiveLoginToken(response.credential);
		}
		document.getElementById("signInDiv").hidden = true;

		if (document.getElementById("signOutButton")) {
			document.getElementById("signOutButton").hidden = false;
		}

		window.location.reload(); // explicitly call to refresh SPA
	}

	function handleSignOut(event) {
		// set user to empty object
		setUser({});

		// delete accessToken
		document.cookie = "accessToken =;max-age=0";
		token = document.cookie;

		document.getElementById("signInDiv").hidden = false;
		document.getElementById("signOutButton").hidden = true;

		window.location.reload(); // explicitly call to refresh SPA
	}

	// useEffect should only run once here
	useEffect(() => {
		// console.log("Token: ", token);
		// and only if there is no user signed in 
		if (!token || token === null) {

			// following tag is necessary as google object is async and may not be loaded yet (linter will complain otherwise)
			/* global google */
			window.google.accounts.id.initialize({
				client_id: "490978297793-oekvugs12d2452ihqfi8vsl7ulqpoktr.apps.googleusercontent.com",
				callback: handleCallbackResponse
			});

			window.google.accounts.id.renderButton(
				document.getElementById("signInDiv"),
				{ theme: "outline", size: "large" }
			);
		}
	}, []);

	// if no user, show sign in button
	// if user, show log out button
	return (
		<div className="App container-fluid">
			<div className="row">
				<div className="col-sm-12">
					<Header />
					<SelectedFilters />
				</div>
			</div>
			<div id="signInDiv"></div>
			{token &&
				<button id="signOutButton" onClick={(e) => handleSignOut(e)}>Sign Out</button>
			}
			<div className="row">
				<ConnectedRouter />
			</div>
		</div >
	)
};

const Dashboard = () => (
	<div className="dashboard">
		<Link to="/domains">Filter by Domains</Link>
		<VisibleLeads />
	</div>
);


/**

				<VisibleDomainsList />
				<VisibleLeads />
				<VisibleDetails />
**/


export default App;