import { ADD_LEAD, REQUEST_LEADS, RECEIVE_LEADS } from "../constants/action-types";
import { UPDATE_LEAD } from "../actions/leads";
import { FILTER_BY_DOMAIN, TOGGLE_TESTS_FILTER, TOGGLE_DELETED_FILTER, UPDATE_DATE_FILTER } from "../actions/index";

const initialState = {
  data: [],
  isFetching: false,
  didInvalidate: true,
  lastUpdated: null
};

const leadsReducer = (state = initialState, action) => {
	let domains = {};
  	switch (action.type) {
    	case FILTER_BY_DOMAIN:
    		let leadState = state.leads;
    		if (!state.isFetching) {
    			leadState = {
        			data: [],
        			didInvalidate: true,
        			isFetching: false
      			}
    		}
    		return Object.assign({}, state, leadState);
    	case REQUEST_LEADS:
      		return Object.assign({}, state, {
        		isFetching: true,
        		didInvalidate: false,
      		})
    	case RECEIVE_LEADS:
      		return Object.assign({}, state, {
        		isFetching: false,
        		didInvalidate: false,
        		data: action.leads,
        		lastUpdated: action.receivedAt
      		})
    	case TOGGLE_TESTS_FILTER:
    		return Object.assign({}, state, {
        		didInvalidate: true,
      		});
    	case TOGGLE_DELETED_FILTER:
    		return Object.assign({}, state, {
        		didInvalidate: true,
      		});
    	case UPDATE_DATE_FILTER:
    		return Object.assign({}, state, {
        		didInvalidate: true,
      		});
    	case UPDATE_LEAD:
    		return Object.assign({}, state, {
        		didInvalidate: true,
      		});
    	default:
      		return state;
  	}
};

export default leadsReducer;