import config from '../constants/config';
import { shouldFetch, authFailed } from './index';
import fetch from 'cross-fetch';

export const REQUEST_DOMAINS = 'REQUEST_DOMAINS';
export function requestDomains() {
	return {
		type: REQUEST_DOMAINS,
	};
}

export const RECEIVE_DOMAINS = 'RECEIVE_DOMAINS';
export function receiveDomains(json) {
	let results = [];
	if (json !== undefined) {
		results = json.body.results;
		// console.log("In domains.js RECEIVE_DOMAINS: ", json.body.results);
	}
	return {
		type: RECEIVE_DOMAINS,
		domains: results,
		receivedAt: Date.now()
	};
}


export const FAILED_RECEIVE_DOMAINS = 'FAILED_RECEIVE_DOMAINS';
export function failedReceiveDomains() {
	return {
		type: FAILED_RECEIVE_DOMAINS,
		receivedAt: Date.now()
	};
}

export function fetchDomains(state) {
	let url = config.domain + "/api/getDomains?code=laXZu9aFg0kGdz/KbGAmA1SnptchHffN0x5XlAeml92XHTjsRElUPA==";
	// let headers = { headers: { "X-ZUMO-AUTH": state.user.accessToken } };
	let headers = { headers: { "Content-Type": "application/json" } };

	if (state.app.showTests) {
		url += "&withtests=true";
	}
	if (state.app.showDeleted) {
		url += "&withdeleted=true";
	}
	if (state.app.showBefore) {
		url += "&before=" + state.app.showBefore;
	}
	if (state.app.showAfter) {
		url += "&after=" + state.app.showAfter;
	}
	return dispatch => {
		console.log("REQUESTING DOMAINS FROM: ", url);
		dispatch(requestDomains());

		return fetch(url, headers)
			.then(response => {
				// console.log("RESPONSE: ", response);
				if (response.status === 401) {
					dispatch(authFailed());
				}
				return response.json();
			})
			.catch(error => console.log('Error Fetching Domains:', error))
			.then(json => dispatch(receiveDomains(json)));
	}
}

export function fetchDomainsIfNeeded() {
	return (dispatch, getState) => {
		let areNeeded = shouldFetch('domains', getState());
		if (areNeeded) {
			// Dispatch a thunk from thunk!
			return dispatch(fetchDomains(getState()));
		}
		else {
			return Promise.resolve();
		}
	}
}
