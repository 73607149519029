import React from 'react'
import { connect } from 'react-redux'
import { toggleTestsFilter, toggleDeletedFilter, changeDateFilters } from '../actions';
import { fetchLeadsIfNeeded } from '../actions/leads';
import { fetchDomainsIfNeeded } from '../actions/domains';

const mapStateToProps = state => {
  return {
    showTests: state.app.showTests,
    showDeleted: state.app.showDeleted,
    showBefore: state.app.showBefore,
    showAfter: state.app.showAfter
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    onToggleTestsClick: (e) => {
    	e.preventDefault();
      	dispatch(toggleTestsFilter());
      	dispatch(fetchDomainsIfNeeded());
      	dispatch(fetchLeadsIfNeeded());
    },
    onToggleDeletedClick: (e) => {
    	e.preventDefault();
      	dispatch(toggleDeletedFilter());
      	dispatch(fetchDomainsIfNeeded());
      	dispatch(fetchLeadsIfNeeded());
    },
    onChangeDateFilters: (after, before) => {
    	dispatch(changeDateFilters(before, after));
      	dispatch(fetchDomainsIfNeeded());
      	dispatch(fetchLeadsIfNeeded());
    },
  }
}

let SelectedFilters = ({showTests, showDeleted, showBefore, showAfter, onToggleTestsClick, onToggleDeletedClick, onChangeDateFilters}) => {
	let showTestsLabel = showTests ? "Yes" : "No";
	let showDeletedLabel = showDeleted ? "Yes" : "No";
	let localShowAfter, localShowBefore;
	
	return (
		<div className="row p-1 border-bottom">
			<div className="col-6 border-right">
			
				<div className="row">
					<label className="col-6">leads after:</label>
					<div className="col-6"><input type="date" defaultValue={showAfter} ref={node => localShowAfter = node} onChange={e => {
				onChangeDateFilters(localShowAfter.value, localShowBefore.value);
			}} /></div>
				</div>
				<div className="row">
					<label className="col-6">leads before:</label>
					<div className="col-6"><input type="date" ref={node => localShowBefore = node} onChange={e => {
				onChangeDateFilters(localShowAfter.value, localShowBefore.value);
			}} /></div>
				</div>
				
			</div>
			<div className="col-6">
			
				<div className="row">
					<label className="col-6">show tests?</label>
					<div className="col-6"><a href="" onClick={e => {
				onToggleTestsClick(e);}}>{showTestsLabel}</a></div>
				</div>
			
				<div className="row">
					<label className="col-6">show deleted?</label>
					<div className="col-6"><a href="" onClick={e => {
				onToggleDeletedClick(e);}}>{showDeletedLabel}</a></div>
				</div>
			</div>
		</div>
	);
}

SelectedFilters = connect(
  mapStateToProps,
  mapDispatchToProps
)(SelectedFilters);

export default SelectedFilters