import { connect } from 'react-redux'
import { filterByDomain, toggleLeadDetails } from '../actions';
import { fetchLeads } from '../actions/leads';
import DomainList from '../components/DomainList';


const mapStateToProps = state => {
  return {
    domains: state.domains,
    accessToken: state.accessToken,
    filter: state.filter
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    onDomainClick: domain => {
      	dispatch(filterByDomain(domain));
      	dispatch(fetchLeads(domain));
    }
  }
}

const VisibleDomainList = connect(
  mapStateToProps,
  mapDispatchToProps
)(DomainList);

export default VisibleDomainList