import React from 'react'
import PropTypes from 'prop-types'
import Lead from './Lead'
import LeadName from './LeadName'
import TextBox from './TextBox';
import TextArea from './TextArea';
import { Link } from 'react-router-dom'

const FormDetails = ({ forms, inView, onFormChange }) => {
	let reducer = (acc, cur) => {
		if (inView === cur._id) {
			acc = cur;
		}
		return acc;
	};
	let form = forms.data.reduce(reducer, null);

	if (form) {

	onFormChange = onFormChange(form._id, form);
	return (
	<div className="col-sm-12">
		<Link to="/">Done</Link>
      	<div className="card">
      		<div className="card-body">
      			<div className="container">
  					<TextBox label="Form Name" type="text" name="name" parent={ form } keyname="name" onChange={onFormChange} />
  					<TextBox label="Form ID" type="text" name="form_id" parent={ form } keyname="form_id" onChange={onFormChange} />
      				<TextBox label="Successful Submission Redirects to" type="text" name="redirect" parent={ form } keyname="redirect" onChange={onFormChange} />
				</div>
			</div>
      	</div>
      	<div className="card">
      		<div className="card-body">
      			<div className="container">
      				<h3>Notification Email</h3>
      				<div className="form-check form-group">
  						<input className="form-check-input" type="checkbox" value="" id="email_notification_disabled" name="emailNotification.disabled" parent={ form.emailNotification } defaultChecked={ form.emailNotification.disabled === true ? "checked" : "" } keyname="disabled" onChange={onFormChange} />
  						<label className="form-check-label" htmlFor="email_notification_disabled">
    						disabled?
  						</label>
					</div>
  					<TextBox label="Recipients (comma separate)" type="text" name="emailNotification.to" parent={ form.emailNotification } keyname="to" onChange={onFormChange} />
  					<TextBox label="From" type="text" name="emailNotification.from" parent={ form.emailNotification } keyname="from" onChange={onFormChange} />
  					<TextBox label="Subject" type="text" name="emailNotification.subject" parent={ form.emailNotification } keyname="subject" onChange={onFormChange} />
  					<TextBox label="Fields to include (comma separate)" type="text" name="emailNotification.messageKeys" parent={ form.emailNotification } keyname="messageKeys" onChange={onFormChange} />
    				<small id="passwordHelpBlock" className="form-text text-muted">This determines which fields will be included in the email. Any columns of the report can be included.</small>
				</div>
			</div>
      	</div>
      	<div className="card">
      		<div className="card-body">
      			<div className="container">
      				<h3>Receipt Email</h3>
      				<div className="form-check form-group">
  						<input className="form-check-input" type="checkbox" value="" id="email_receipt_disabled" name="emailReceipt.disabled" parent={ form.emailReceipt } defaultChecked={ form.emailReceipt.disabled === true ? "checked" : "" } keyname="disabled" onChange={onFormChange} />
  						<label className="form-check-label" htmlFor="email_receipt_disabled">
    						disabled?
  						</label>
					</div>
  					<TextBox label="Subject" type="text" name="emailReceipt.subject" parent={ form.emailReceipt } keyname="subject" onChange={onFormChange} />
      				<TextBox label="From Email" type="text" name="emailReceipt.from.email" parent={ form.emailReceipt.from } keyname="email" onChange={onFormChange} />
      				<TextBox label="From Name" name="emailReceipt.from.name"  parent={ form.emailReceipt.from } keyname="name" onChange={onFormChange} />
      				<TextBox label="Reply To (Email)" name="emailReceipt.replyTo"  parent={ form.emailReceipt } keyname="replyTo" onChange={onFormChange} />
      				<TextArea label="HTML Body" name="emailReceipt.body.html"  parent={ form.emailReceipt.body } keyname="html" onChange={onFormChange}></TextArea>
      				<TextArea label="Text Body" name="emailReceipt.body.text"  parent={ form.emailReceipt.body } keyname="text" onChange={onFormChange}></TextArea>
      				<small id="passwordHelpBlock" className="form-text text-muted">leadName in the double mustaches will be replaced with the lead's first name field. Let the developers know if you would like other data to be available to the email.</small>
				</div>
			</div>
      	</div>
  	</div>
	);
	}
	else {
		return (
			<div>No form found.</div>
		);
	}
}


FormDetails.propTypes = {
}

export default FormDetails
