import { ADD_LEAD, REQUEST_LEADS, RECEIVE_LEADS } from "../constants/action-types";
import config from '../constants/config';
import { shouldFetch } from './index';

export function requestLeads() {
	return {
		type: REQUEST_LEADS,
	};
}

export function receiveLeads(json) {
	let results = [];
	if (json.body && json.body !== undefined) {
		results = json.body.results;
	}
	return {
		type: RECEIVE_LEADS,
		leads: results,
		receivedAt: Date.now()
	};
}

export function fetchLeads(state) {
	let url = config.domain + "/api/domainLeads?code=0rq3aLPPtFHOxRtTG3Na1ZIMo9JOyMyxRDaEoNheYPXqBIg1snPoqw==&domain=" + state.app.filter;
	let headers = {
		headers:
		{
			//"X-ZUMO-AUTH": state.user.accessToken,
			"Content-Type": "application/json"
		}
	};

	if (state.app.showTests) {
		url += "&withtests=true";
	}
	if (state.app.showDeleted) {
		url += "&withdeleted=true";
	}
	if (state.app.showBefore) {
		url += "&before=" + state.app.showBefore;
	}
	if (state.app.showAfter) {
		url += "&after=" + state.app.showAfter;
	}

	return dispatch => {
		if (!state.app.filter) {
			return dispatch(receiveLeads({ results: [] }));
		}

		dispatch(requestLeads());

		return fetch(url, headers)
			.then(response => response.json())
			.catch(error => console.log('Error Fetching Leads:', error))
			.then(json => dispatch(receiveLeads(json)));
	}
}

export function fetchLeadsIfNeeded() {
	return (dispatch, getState) => {
		let areNeeded = shouldFetch('leads', getState());
		if (areNeeded) {
			// Dispatch a thunk from thunk!
			return dispatch(fetchLeads(getState()));
		}
		else {
			return Promise.resolve();
		}
	}
}

export const UPDATE_LEAD = 'UPDATE_LEAD';
export function updateLead() {
	return {
		type: UPDATE_LEAD
	}
}
export function doUpdateLead(data) {
	let url = config.domain + "/api/updateLead?code=TWXJW4Kl6Krqf2ou5SAulBAPxGzJpXMgeG/b0jzy1ImXyiHLNraXhg==";

	return (dispatch, getState) => {
		let state = getState();
		let headers = {
			headers:
			{
				"X-ZUMO-AUTH": state.user.accessToken,
				"Content-Type": "application/json"
			}
		};
		headers['body'] = JSON.stringify(data);
		headers['method'] = 'POST';

		return fetch(url, headers)
			.then(response => response.json())
			.then(json => {
				dispatch(updateLead());
				dispatch(fetchLeadsIfNeeded());
			});
	};
}
