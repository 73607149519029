import React from 'react'
import PropTypes from 'prop-types'
import LeadName from './LeadName'
import LeadMoreDetails from './LeadMoreDetails'
import { Link } from 'react-router-dom'

const Lead = ({ onClick, lead, expanded, onToggleMore, onToggleTest, onToggleDelete }) => {
	let testLabel = lead._test ? "fa-eye-slash" : "fa-eye";
	let deleteLabel = lead._deleted ? "fa-recycle" : "fa-trash";
	return (
    <div className="list-group-item" key={lead._id}>
    	<div className="row">
    	<div className="col-sm-12 col-md-10">
      	<LeadName lead={lead} withLink={true} />
    		<div className="">
    		{ new Date(lead.inserted_datetime).toLocaleString() }
    		</div>
    	</div>
        <div className="col-sm-12 col-md-2 text-right">
        	<a className="btn btn-default" href="#" onClick={(e) => {
        		e.preventDefault();
        		onToggleTest(lead);
        	}}><i className={ "fas " + testLabel }></i></a>
        	<a className="btn btn-default" href="#" onClick={(e) => {
        		e.preventDefault();
        		onToggleDelete(lead);
        	}}><i className={ "fas " + deleteLabel }></i></a>
        </div>
        </div>
      </div>
	);
}

Lead.propTypes = {
  onClick: PropTypes.func.isRequired,
}

export default Lead
