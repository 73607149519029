import React from 'react'
import PropTypes from 'prop-types'
import Lead from './Lead'
import { Link } from 'react-router-dom'

const DomainList = ({ domains, accessToken, filter, onDomainClick }) => {
	if (domains.isFetching) {
		return (
			<div className="col-sm-12">
				<p>Loading...</p>
			</div>
		);
	}

	return (
		<div className="col-sm-12">
			<h2>Active Pages</h2>
			<ul className="list-group">
				{domains.data.map((domain, index) => (
					<li key={index} className="list-group-item">
						<div className="row">
							<div className="col-lg-6 col-md-12 col-sm-12">
								<Link to={"/domains/" + domain.domain}>{domain.domain}</Link>
							</div>
							<div className="col-lg-2 col-md-6">
								<div>total: {domain.counter}</div>
							</div>
							<div className="col-lg-4 col-md-6">
								<div>last received: {new Date(domain.last_received).toDateString()}</div>
							</div>
						</div>
					</li>
				))}
			</ul>
		</div>
	);
}


DomainList.propTypes = {
}

export default DomainList