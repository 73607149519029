import config from '../constants/config';
import { shouldFetch } from './index';
import fetch from 'cross-fetch';

export const UPDATE_FORM = 'UPDATE_FORM';
export function updateForm() {
	return {
		type: UPDATE_FORM,
		receivedAt: Date.now()
	};
}

export function doUpdateForm(id, data) {
	console.log("updating form in leads-reporting-gui's forms.js doUpdateForm() function", id, " with data:", data);

	let url = config.domain + "/api/updateForm?code=cu2N1uMu4aGEKRFc7eT2z9DHOprTf6/KPp0/nBlIFevwLBU7XL0maw==";

	return (dispatch, getState) => {
		let state = getState();
		let headers = {
			headers:
			{
				"X-ZUMO-AUTH": state.user.accessToken,
				"Content-Type": "application/json"
			}
		};
		data['_id'] = id;
		headers['body'] = JSON.stringify(data);
		headers['method'] = 'POST';

		return fetch(url, headers)
			.then(response => response.json())
			.then(json => {
				dispatch(updateForm());
			});
	};
}

export const CREATE_FORM = 'CREATE_FORM';
export function createForm() {
	let url = config.domain + "/api/createForm?code=GA7HuNTS/0dThETva1wksra85ne3wJpD4nKDmqqF2JPTOewb2O/pGA==";
	console.log("In forms.js createForm()");
	return (dispatch, getState) => {
		let state = getState();
		let headers = {
			headers:
			{
				"X-ZUMO-AUTH": state.user.accessToken,
				"Content-Type": "application/json"
			}
		};

		headers['method'] = 'POST';
		console.log("before fetch()");

		return fetch(url, headers)
			.then(response => response.json())
			.then(json => {
				dispatch(fetchForms(state));
			});
	};
}

export const REQUEST_FORMS = 'REQUEST_FORMS';
export function requestForms() {
	return {
		type: REQUEST_FORMS,
	};
}

export const RECEIVE_FORMS = 'RECEIVE_FORMS';
export function receiveForms(json) {
	let results = [];
	if (json !== undefined) {
		results = json.body.results;
	}
	return {
		type: RECEIVE_FORMS,
		forms: results,
		receivedAt: Date.now()
	};
}

export function fetchForms(state) {
	let url = config.domain + "/api/forms?code=9C/TUozcRA6SXzFC0Kejvk7AP0y6L662wLPQSD2PjDQdjHeAIjKFYw==";
	let headers = { headers: { "X-ZUMO-AUTH": state.user.accessToken } };

	if (state.app.showTests) {
		url += "&withtests=true";
	}
	if (state.app.showDeleted) {
		url += "&withdeleted=true";
	}
	if (state.app.showBefore) {
		url += "&before=" + state.app.showBefore;
	}
	if (state.app.showAfter) {
		url += "&after=" + state.app.showAfter;
	}

	return dispatch => {

		dispatch(requestForms());

		return fetch(url, headers)
			.then(response => response.json())
			.catch(error => console.log('Error Fetching Forms:', error))
			.then(json => dispatch(receiveForms(json)));
	}
}

export function fetchFormsIfNeeded() {
	return (dispatch, getState) => {
		let areNeeded = shouldFetch('forms', getState());
		if (areNeeded) {
			// Dispatch a thunk from thunk!
			return dispatch(fetchForms(getState()));
		}
		else {
			return Promise.resolve();
		}
	}
}
