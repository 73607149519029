import React from "react";
import { connect } from 'react-redux'
import { filterByDomain, fetchLeads, toggleLeadDetails, googleLogin } from '../actions'
import { BrowserRouter as Router, Route, Link, Switch } from 'react-router-dom';
import ConnectedLogin from "./ConnectedLogin.js";
import VisibleLeads from "../containers/VisibleLeads";
import VisibleDomainsList from "../containers/VisibleDomainsList";
import VisibleForms from "../containers/VisibleForms";
import VisibleForm from "../containers/VisibleForm";
import VisibleDetails from "../containers/VisibleDetails";


const mapStateToProps = (state, ownProps) => {
	return {
		accessToken: state.user.accessToken,
	}
}

const mapDispatchToProps = (dispatch, ownProps) => {
	return {
	}
}

const toRender = ({ accessToken }) => {
	if (!accessToken || accessToken === null) {
		return (<ConnectedLogin />);
	}
	else {
		return (
			<Router>
				<Switch>
					<Route path="/domains/:view" component={DomainsView} />
					<Route path="/domains" component={Domains} />
					<Route path="/lead/:id" component={LeadDetails} />
					<Route path="/form/:id" component={FormDetails} />
					<Route component={Domains} />
				</Switch>
			</Router>
		);
	}
}

const Domains = () => (
	<div className="col-sm-12">
		<div className="row">
			<div className="col-sm-6">
				<VisibleDomainsList />
			</div>
			<div className="col-sm-6">
				<VisibleForms />
			</div>
		</div>
	</div>
);

const DomainsView = ({ match }) => (
	<VisibleLeads filter={match.params.view} />
);

const LeadDetails = ({ match }) => (
	<VisibleDetails id={match.params.id} />
);

const FormDetails = ({ match }) => (
	<VisibleForm id={match.params.id} />
);


const ConnectedRouter = connect(
	mapStateToProps,
	mapDispatchToProps
)(toRender);

export default ConnectedRouter;