import { combineReducers } from 'redux';
import domainsReducer from '../reducers/domains';
import formsReducer from '../reducers/forms';
import leadsReducer from '../reducers/leads';
import usersReducer from '../reducers/users';
import appReducer from '../reducers/app';

const rootReducer = combineReducers({
	domains: domainsReducer,
	forms: formsReducer,
	leads: leadsReducer,
	user: usersReducer,
	app: appReducer
})
export default rootReducer;