let localhostDomain = "http://localhost:8000";
let prodDomain = "https://leads-microservices.elliance.com:7071";           // self-hosted URI
// let prodDomain = "https://elliance-leads-reporting.azurewebsites.net";   // Older Azure Func(k)tions URI
let googleAuthDomain = "https://leads-reporting.elliance.com";

const config = {};
config.domain = prodDomain; // TOGGLE WITH localhostDomain. CHANGE BACK TO PROD prodDomain(AND BUILD) BEFORE COMMITTING CHANGES
config.googleAuthDomain = googleAuthDomain;

export default config;
