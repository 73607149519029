import config from '../constants/config';
import { ADD_LEAD, REQUEST_LEADS, RECEIVE_LEADS } from "../constants/action-types";
import { fetchDomainsIfNeeded } from '../actions/domains';
import { fetchFormsIfNeeded } from '../actions/forms';
import fetch from 'cross-fetch';

//let testDomain = "http://localhost:7071";
//let prodDomain = "https://elliance-leads-reporting.azurewebsites.net";
//let domain = prodDomain;

export const FILTER_BY_DOMAIN = 'FILTER_BY_DOMAIN';
export function filterByDomain(filter) {
	return {
		type: FILTER_BY_DOMAIN,
		filter: filter
	}
}

export const TOGGLE_TESTS_FILTER = 'TOGGLE_TESTS_FILTER';
export function toggleTestsFilter() {
	return {
		type: TOGGLE_TESTS_FILTER
	}
}
export const TOGGLE_DELETED_FILTER = 'TOGGLE_DELETED_FILTER';
export function toggleDeletedFilter() {
	return {
		type: TOGGLE_DELETED_FILTER
	}
}
export const UPDATE_DATE_FILTER = 'UPDATE_DATE_FILTER';
export function changeDateFilters(before, after) {
	return {
		type: UPDATE_DATE_FILTER,
		before: before,
		after: after
	}
}

export const LEAD_TOGGLE_DETAILS = 'LEAD_TOGGLE_DETAILS';
export function toggleLeadDetails(id, expanded, expand) {
	let expandedClone = expanded.slice(0)
	let location = expanded.indexOf(id);
	if (expand && location < 0) {
		expandedClone.push(id);
	}
	else if (!expand) {
		expandedClone.splice(location, 1);
	}
	return {
		type: LEAD_TOGGLE_DETAILS,
		id: id,
		expanded: expandedClone
	}
}

export const addLead = lead => ({ type: ADD_LEAD, payload: lead });

export function shouldFetch(type, state, subreddit) {
	const nodes = state[type];
	if (nodes.isFetching) {
		return false;
	}
	else if (!nodes || nodes.data.length <= 0) {
		return true;
	}
	else {
		return nodes.didInvalidate;
	}
}

export function googleLogin(googleUser) {
	return dispatch => {
		var id_token = googleUser.getAuthResponse().id_token;

		// console.log("AUTH RESPONSE: ", googleUser.getAuthResponse()); // dump response for debugging

		var googleId = googleUser.getId();

		// making sure the login request has an elliance.com email.
		// console.log("USER EMAIL: ", googleUser.profileObj.email); // Show user for debugging

		if (!(googleUser.profileObj.email).includes('@elliance.com')) {
			return;
		}

		let url = config.googleAuthDomain + "/.auth/login/google";
		let data = { id_token: id_token };

		fetch(url, {
			method: 'POST',
			body: JSON.stringify(data),
			headers: { "Content-Type": "application/json" }
		})
			.then(response => response.json())
			.then(json => {
				dispatch(receiveLoginToken(json.authenticationToken));
				dispatch(fetchDomainsIfNeeded());
				dispatch(fetchFormsIfNeeded());
			})
			.catch(error => {
				console.error('There was an error!', error);
			});
	}
}

export const RECEIVE_LOGIN_TOKEN = 'RECEIVE_LOGIN_TOKEN';
function receiveLoginToken(authenticationToken) {
	let cookies = document.cookie.split('&').reduce((acc, cur) => {
		let split = cur.split('=');
		acc[split[0]] = split[1];
		return acc;
	}, {});
	document.cookie = "accessToken=" + authenticationToken;
	return {
		type: RECEIVE_LOGIN_TOKEN,
		accessToken: authenticationToken,
		receivedAt: Date.now()
	};
}

export const AUTH_FAILED = 'AUTH_FAILED';
export function authFailed() {
	return {
		type: AUTH_FAILED,
		accessToken: null,
		receivedAt: Date.now()
	};
}

