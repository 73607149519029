import { connect } from 'react-redux'
import { doUpdateForm } from '../actions/forms'
import FormDetails from '../components/FormDetails';


const mapStateToProps = (state, ownProps) => {

  return {
    forms: state.forms,
    inView: ownProps.id,
    accessToken: state.accessToken
  }
}

function buildQueueObject(name, val, queue) {
	name = name.split('.');
	let cur = queue;
	for (let i = 0; i < name.length; i++) {
		// set as current object
		if (cur[name[i]] === undefined) {
			cur[name[i]] = {};
		}
		// if last, set cur object = val;
		if (i === (name.length-1)) {
			cur[name[i]] = val;
		}
		else {
			cur = cur[name[i]];
		}
	}
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    onFormChange: (formid, queue) => {
    	//let queue = {};
    	let timer = null;
    	return (event) => {
    		clearInterval(timer);
    		//queue[event.target.name] = event.target.value;
        // Cover for checked vs. value if type is a checkbox
    		buildQueueObject(event.target.name, event.target.type === "checkbox" ? event.target.checked : event.target.value, queue);
    		timer = setTimeout(function() {
    			console.log("saving form data."); //, queue);
    			dispatch(doUpdateForm(formid, queue));
    		}, 3000); /// 3 seconds is arbitrary. seemed like a good time during testing.
    	};
    }
  }
}

const VisibleForm = connect(
  mapStateToProps,
  mapDispatchToProps
)(FormDetails);

export default VisibleForm
