import React from "react";
import { connect } from "react-redux";
import FilterLink from '../containers/FilterLink'
import '../App.css';

const mapStateToProps = state => {
  return { filter: state.filter };
};

const FilterText = ({ filter }) => (
	<div>{ filter }</div>
);

const Filter = connect(mapStateToProps)(FilterText);

export default Filter;