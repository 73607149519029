import React from 'react'
import PropTypes from 'prop-types'
import Lead from './Lead'
import { Link } from 'react-router-dom'

const FormList = ({ forms, accessToken, filter, onCreateClick }) => {
	if (forms.isFetching) {
		console.log("In FormList forms.isFetching...");
		return (
			<div className="col-sm-12">
				<p>Loading...</p>
			</div>
		);
	}

	return (
		<div className="col-sm-12">
			<h2>Forms</h2>
			<ul className="list-group">
				{forms.data.map((form, index) => (
					<li key={index} className="list-group-item">
						<div className="row">
							<div className="col-md-12">
								<Link to={"/form/" + form._id}>{form.name}</Link>
							</div>
						</div>
					</li>
				))}
			</ul>
			<p>
				<button className="btn btn-primary" onClick={onCreateClick}>Create New Form</button>
			</p>
		</div>
	);
}


FormList.propTypes = {
}

export default FormList