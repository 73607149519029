import * as moment from 'moment';
import { FILTER_BY_DOMAIN, TOGGLE_TESTS_FILTER, TOGGLE_DELETED_FILTER, UPDATE_DATE_FILTER } from "../actions/index";

const initialState = {
	showTests: false,
	showDeleted: false,
	showBefore: null,
	showAfter: moment().format('YYYY-MM-01'),
	filter: ''
};

const appReducer = (state = initialState, action) => {
	let invalidators = state.invalidators;
	switch (action.type) {
		case FILTER_BY_DOMAIN:
			return Object.assign({}, state, {
				filter: action.filter,
			});
		case TOGGLE_TESTS_FILTER:
			return Object.assign({}, state, {
				showTests: !state.showTests
			});
		case TOGGLE_DELETED_FILTER:
			return Object.assign({}, state, {
				showDeleted: !state.showDeleted
			});
		case UPDATE_DATE_FILTER:
			return Object.assign({}, state, {
				showBefore: action.before,
				showAfter: action.after
			});
		default:
			return state;
	}
};

export default appReducer;