import React from 'react'
import { NavLink } from 'react-router-dom'

import { connect } from 'react-redux'
import { filterByDomain, fetchLeads } from '../actions'
import Link from '../components/Link'

const mapStateToProps = (state, ownProps) => {
  return {
    active: ownProps.filter === state.filter,
    accessToken: state.accessToken
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
  }
}

const FilterLink = connect(
  mapStateToProps,
  mapDispatchToProps
)(Link)

export default FilterLink